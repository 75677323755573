<template>
  <div>      
    <b-table :items="JSON.parse(fileData.file)" responsive sticky-header="200px"> </b-table>
  </div>
</template>

<script>
export default {
  props: ["fileData"]  
};
</script>

<style>
</style>